import React from "react";

function HansaLogo(
  props: React.ImgHTMLAttributes<HTMLImageElement>,
): React.ReactElement<HTMLImageElement> {
  return (
    <img src="/assets/hansa-logo-default.svg" alt="WithHansa Logo" {...props} />
  );
}

function HansaLogoIcon(
  props: React.ImgHTMLAttributes<HTMLImageElement>,
): React.ReactElement<HTMLImageElement> {
  return (
    <img src="/assets/hansa-logo-small.svg" alt="WithHansa Logo" {...props} />
  );
}
export { HansaLogo, HansaLogoIcon };
